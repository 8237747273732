<template>
  <div class="star-product">
    <div class="ad" v-if="bannerData">
      <img :src="bannerData.webImg" />
    </div>
    <h2></h2>
    <ul class="star-list container" v-if="starProducts && starProducts.length > 0">
      <li v-for="item,index in starProducts" :key="index" :class="'item-' + (index + 1)">
        <img :src="item.webImg" alt="" />
        <div class="content">
          <p class="name" >
            <span class="desc" v-for="_item in item.title.split('\n')">
              {{_item}}
            </span>
          </p>
          
          <a :href="item.openLink" >Details</a>
        </div>
        
        
      </li>
     
    </ul>
    <div class="product-list-box" v-if="productList && productList.length > 0">
        <div class="product-list container" v-for="item,index in productList " :key="index" v-show="item.list && item.list.length > 0" :id="item.name">
        <div  :class="'logo ' + (index%2 == 0 ? 'right':'left')" >{{item.name}}</div>
        <ul  >
          <li v-for="child,childindex in item.list" :key="childindex">
              <a :href="child.openLink" >
                <img :src="child.webImg" alt="">
                <div class="content">
                    <span class="en">{{child.title}}</span>
                    <span class="cn">{{child.subTitle}}</span>
                </div>
              </a>
          </li>
          
        </ul>
      </div>
    </div>
    
  </div>
</template>

<script>
import Utils from '@/api/Utils.js' 
export default {
  name: 'Star-Product',
  components: {
    
  },
  data(){
    return {
        bannerData: null,
        starProducts: null,
        productList: null
    }
  },
  mounted(){
    
    console.log(this.$route.hash)
    Utils.post(`v1/outer/article/star`, {"starLimit":-1}).then((res)=>{
      if(res && res.code == 200){
        this.bannerData = res.data.strBanner
        this.starProducts = res.data.starProducts
        this.productList = res.data.productList
        console.log(this.starProducts)
        if(this.$route.hash){
          setTimeout(()=>{
            this.$nextTick(()=>{
              location.href = this.$route.hash; 
            })
          },200)
        }

      }
    })
  }
}
</script>
<style scoped lang="stylus">
.star-product{
  background : #f7f8f8;
  padding-bottom: 20px;
}
.ad{
   height: 538px;
   overflow: hidden;
   position relative
   img{
      display block 
      height: 538px;
      margin: 0 auto;
      position absolute
      left: 50%
      transform translate3d(-50%,0,0)
      top: 0
   }
}
h2{
  margin: 100px 0 132px;
  background: url('./title.png') 50% 0 no-repeat;
  background-size: auto 31px
  height 31px;
}
.star-list{
    li{
        display block;
        position relative
        margin: 0 auto 15px;
        width 853px;
    }
    img{
      display block;
      width: 100%;
      position relative
    }
    .content{
      position absolute
    }
    
    .name{
        font-size: 12px;
        line-height 2;
        font-weight bold
        margin-bottom 30px
        span{
          display block;
        }
    }
    a{
        width 107px;
        height 25px;
        line-height 25px;
        border 1px solid #d0d0d0;
        display block
        border-radius 15px;
        text-align center;
        color: #3e3a39;
        &:hover,&.active{
          background : #353e66;
          border 1px solid #353e66;
          color : #fff;
        }
    }
    .item-1{
      .content{
        left: 502px
        top: 116px;
      }
    }
    .item-2{
      .content{
        left: 10px
        top: 188px;
      }
    }
    .item-3{
      .content{
        left: 423px
        top: 118px;
      }
      
    }
    .item-4{
      .content{
        left: 10px
        top: 203px;
      }
    }
}
.product-list-box{
  padding: 0px 0 0;
  
}
.product-list{
    
    
    position: relative;
    width 853px
    .logo{
        position absolute
        z-index: 3
        top: 25px;
        line-height 1
        width 100%
        font-size: 78px
        font-weight bold
        color: #dddfde;
    }
    .right{
      text-align right
    }
   
    
    ul{
      width 841px
      margin:  auto 0;
      position relative
      z-index: 5;
      padding: 100px 0 0;
    }
    li{
      display inline-block
      width 194px;
      height 194px;
      position relative
      margin: 0 7px 40px ;
      z-index: 2
      a,img{
        width 194px;
        height 194px;
        display block;
      }
      &:hover{
        .content{
            opacity: 1;
        }
      }
    }
    
    .content{
        opacity : 0;
        transition : opacity .7s; 
        position absolute
        width 100%
        height 100%
        z-index: 2
        top: 0
        left: 0
        text-align center
        vertical-align middle;
        background rgba(0,0,0,.4);
    }
    span{
      display block;
      color #fff;
    }
    .en{
      font-size: 25px;
      margin-bottom: 10px;
      margin-top: 78px;
    }
    .cn{
      font-size: 14px;
    }
}
</style>
